//NEWSLETTER
const linkNewsletter = document.getElementById('text-vertical');
const divNewsletter = document.getElementById('div-newsletter');
const divformNewsletter = document.getElementById('div-form-newsletter');
const spanNewsletter = document.getElementById('span-newsletter')
const formNewsletter = document.getElementById('form-newsletter')
const inputEmail = document.getElementById('input-email')

if (document.getElementById('text-vertical')) {
    linkNewsletter.addEventListener('click', function (e) {
        e.preventDefault()
        divNewsletter.classList.toggle('extend')
        linkNewsletter.classList.toggle('margin-left')
        spanNewsletter.classList.toggle('span-newsletter')
        formNewsletter.classList.toggle('form-newsletter')
        divformNewsletter.classList.toggle('div-form-newsletter')
    })
}


const bodyTag = document.getElementsByTagName('body');
if (document.getElementById('text-vertical')) {
    bodyTag[0].addEventListener('click', clickedOrNot, true);
}

function clickedOrNot(e) {
    if (e.target !== divNewsletter && e.target !== inputEmail) {
        if (divNewsletter.classList.contains('extend')) {
            divNewsletter.classList.remove('extend');
        }
        if (linkNewsletter.classList.contains('margin-left')) {
            linkNewsletter.classList.remove('margin-left');
        }
        spanNewsletter.classList.remove('span-newsletter')
        formNewsletter.classList.remove('form-newsletter')
        divformNewsletter.classList.remove('div-form-newsletter')
    }
}




// //SLIDER
// const widthScreen = screen
// const swiperSlide = document.getElementsByClassName('swiper-slide')
// for (const swiperSlideElement of swiperSlide) {
//     swiperSlideElement.style.width = widthScreen.availWidth+'px' + 800+'px'
// }
//
//
// const swiperSlide2 = document.getElementsByClassName('swiper-slide2')
// for (const swiperSlideElement of swiperSlide2) {
//     swiperSlideElement.style.width = widthScreen.availWidth+'px' + 80000 +'px'
// }

//ACCORDION
const accordionitem = document.getElementsByClassName('accordion-item')
const svg = document.getElementById('svg-immo')
for (const accordionElement of accordionitem) {
    accordionElement.addEventListener('click', function (e) {
        e.preventDefault()
        if (accordionElement.dataset.key === '0') {
            svg.classList.remove("under1")
            svg.classList.remove("under2")
            svg.classList.remove("under3")
            svg.classList.add("under0")
        }
        if (accordionElement.dataset.key === '1') {
            svg.classList.remove("under0")
            svg.classList.remove("under2")
            svg.classList.remove("under3")
            svg.classList.add("under1")
        }
        if (accordionElement.dataset.key === '2') {
            svg.classList.remove("under0")
            svg.classList.remove("under1")
            svg.classList.remove("under3")
            svg.classList.add("under2")
        }
        if (accordionElement.dataset.key === '3') {
            svg.classList.remove("under0")
            svg.classList.remove("under1")
            svg.classList.remove("under2")
            svg.classList.add("under3")
        }
    })
}

//JOB
const content = document.getElementById("jobs-content")
const del = document.getElementById("jobs-delete")
const button = document.querySelectorAll('.job-titles-h1')

button.forEach(function (jobs) {
    jobs.addEventListener('click', function (e) {
        del.remove()

        const display = 'carrieres/display'
        const data = { jobId: jobs.dataset.id };

        fetch(display, {
            method: 'POST',
            // headers: {
            //     headers: {
            //         'Accept': 'application/json, text/plain, */*',
            //         'Content-Type': 'application/json'
            //     },
            // },
            body: JSON.stringify(data),
        })
            .then(response => response.json())
            .then(data => {
                const active = document.querySelector(".job-active");
                if (active) {
                    active.classList.remove("job-active");
                }
                jobs.classList.add("job-active");

                content.innerHTML = ''
                const p = document.createElement('p')
                p.innerHTML = data.content
                content.appendChild(p)
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    })
})

const searchButton = document.getElementById('search-detail')
const div = document.getElementById('sub-search-div')

if (searchButton) {
    searchButton.addEventListener('click', function (e) {
        e.preventDefault()
        div.classList.toggle('div-appear')
    })
}

const buttonNavBar = document.getElementsByClassName('navbar-toggler')
const navbar = document.getElementsByClassName('navbar navbar-expand-xl fixed-top')
buttonNavBar[0].addEventListener('click', function (e) {
    e.preventDefault()

    navbar[0].classList.toggle('navbarmobileblack')
})

const footerNav = document.getElementById('footer-nav-mobile')
const navbarNav = document.getElementById('navbarNav')
const navbarToggler = document.getElementsByClassName('navbar-toggler')
const opacity = document.getElementsByClassName('opacity-mobile')

navbarToggler[0].addEventListener('click', function (e) {
    e.preventDefault()
    footerNav.classList.toggle('footer-nav-mobile')
    for (const eElement of opacity) {
        eElement.classList.toggle('add-opacity-mobile')
    }
})

if (window.matchMedia("(max-width: 768px)").matches ) {
    const newsletter = document.getElementById('div-newsletter-mobile')
    if (window.location.pathname === '/carrieres') {
        if (newsletter.style.display === 'none') {
            newsletter.style.visibility = "hidden";
        }
    }
}

(function($) {
    var _dropin = $('.dropdown'),
        _delay = 150,
        _ww = $(window).width();

    $('.undroped', _dropin).on('click', function (e) {
        $(this).hide();
        $(this).siblings('.droped').find('.items').height('auto');
        $(this).siblings('.droped').show();

        var ht = $(this).siblings('.droped').find('.items').height();
        $(this).attr('data-ht', ht);
        $(this).siblings('.droped').find('.items').height(0);
        $(this).siblings('.droped').addClass('open');
        $(this).siblings('.droped').find('.items').animate({height: ht+40}, _delay);
    });

    $('.dropdown-header', _dropin).on('click', function (e) {
        var ht = $(this).closest('.dropdown').find('.undroped').attr('data-ht');

        $(this).closest('.droped').removeClass('open');
        $(this).closest('.droped').find('.items').animate({height: 0}, _delay, function(){
            $(this).closest('.dropdown').find('.undroped').show();
            $(this).closest('.droped').hide();

        });
    });

    /* ------------------- Sticky menu --------------------- */

    var h = 0,
        ht = 0,
        _count = true;

    document.addEventListener('scroll', function (event) {
        h = $(this).scrollTop();
        ht = $(this).height();

        /*
        if (h > 100 && $('nav').hasClass('homepage')){

            if (!$('.navbar-expand-xl').hasClass('sticky')){

                $('.navbar-expand-xl').addClass('sticky');
                $('.navbar-expand-xl').css({'position' : 'fixed', 'background-color' : 'black', 'margin-top': -100});
                $('.navbar-expand-xl').animate({'margin-top' : 0}, 300, function(){

                });
            }
        }

        if (h <= 100 && $('nav').hasClass('homepage')){
            if ($('.navbar-expand-xl').hasClass('sticky')){
                $('.navbar-expand-xl').removeClass('sticky');
                $('.navbar-expand-xl').animate({'margin-top' : -100}, 300, function(){
                    $('.navbar-expand-xl').css({'position' : 'absolute', 'background-color' : 'transparent', 'margin-top': 0, 'height' : 180});
                });
            }
        }
       
         */

        /* ------------------- Counter --------------------- */

        if( h > Number(ht-2200)){

            if (_count){
                _count = false;
                $('.number-content').each(function( i ) {
                    $(this).find('h2').find('strong').addClass('count');
                });

                $('.count').each(function () {
                    $(this).prop('Counter',0).animate({
                        Counter: $(this).text()
                    }, {
                        duration: 8000,
                        easing: 'swing',
                        step: function (now) {
                            $(this).text(Math.ceil(now));
                            _count = false;
                        }
                    });
                });
            }
        }
    }, true);

    /* ------------------- Dropdown --------------------- */

    $('.item').on('click', function (e) {
        e.stopPropagation();
        $(this).siblings().removeClass('selected');
        $(this).toggleClass('selected');
        $(this).closest('.dropdown').find('.undroped-subheader').text($(this).text());
        console.log('item : '+ $(this).attr('data-value'));
        $(this).closest('.dropdown').find('.input-subheader').attr('value', $(this).attr('data-value'));
        
        $(this).closest('.dropdown').find('.undroped-subheader').addClass('selected');
        $(this).closest('.dropdown-header').trigger( "click" );

        $(this).closest('.dropdown').removeClass('open');
        $(this).closest('.droped').find('.items').animate({height: 0}, _delay, function(){
            $(this).closest('.dropdown').find('.undroped').show();
            $(this).closest('.droped').hide();
        });
        if ($(this).closest('.dropdown').find('.good-place').hasClass('selected')) {
            $(this).closest('.dropdown').find('.transparent-font').hide()
        }
    });


    $('header').mousemove(function( e ) {
        if (_ww > 768){
            $('.sticky-text').css({
            left:  e.pageX,
            top:   e.pageY-120
            });
        }
    });

    /* ------------------- lightbox --------------------- */

    var lightbox = $('.lightbox'),
    item = 0;

    $('.lightbox-on').click(function(e){
        e.stopPropagation();
        item = 0;
        console.log('click btn');

        var id = $(this).attr('data-id'),
            max = 0;

        $('#'+id).toggle();
        $('#'+id).animate({opacity: 1}, 300);
        $('#'+id).find('img').each(function( i ) {
            
            var h = $(this).height(),
            w = $(this).width();
            console.log('h : '+h);
            console.log('w : '+w);
           // var horizontal = (h > w) ? true : false;
            max = i;
            item = 1;
            counter(item, max+1);
            

            if (h > w){
                $(this).addClass('horizontal');
            } else {
                $(this).addClass('vertical');
            }

            itemWidth($(this));
        });
    });

    /*
    $('.lightbox-on-second').click(function(e){
        e.stopPropagation();
        item = 0;
        console.log('click btn');

        var id = $(this).attr('data-id'),
            max = 0;

        $('#'+id).toggle();
        $('#'+id).animate({opacity: 1}, 300);
        $('#'+id).find('img').each(function( i ) {

            var h = $(this).height(),
            w = $(this).width();
            console.log('h : '+h);
            console.log('w : '+w);
           // var horizontal = (h > w) ? true : false;
            max = i;
            item = 1;
            counter(item, max+1);


            if (h > w){
                $(this).addClass('horizontal');
            } else {
                $(this).addClass('vertical');
            }

            itemWidth($(this));
        });
    });*/

    lightbox.click(function(e) {
        e.stopPropagation();
        $(this).animate({opacity: 0}, 300, function(){
            $(this).toggle();
        });
    });

    $('.closer').on('click', function (e) {
        e.stopPropagation();
        $(this).parent().animate(
            {opacity: 0},
            300,
            function(){
                $(this).toggle();
            }
        );
    });

    function itemWidth(img){
        var w = 0;
        if (img.hasClass('vertical')){
            w = img.width();
        } else {
            w = img.width();
        }
        img.closest('.items').width(w);
    }

    function counter(item, max){
        $('.counter').text(item + ' / ' + max);
    }

    $('.item', lightbox).on('click, swipeleft', function (e) {
        var wd = $(this).width(),
        last = $(this).parent().find('.item').last(),
        img = $(this).find('.img');
        itemWidth(img);

        $(this).animate(
            {marginLeft: -wd, opacity: 0},
            500,
            'swing',
            function(){
                $(this).insertAfter(last);
                $(this).css({marginLeft: 0, opacity: 1});
            }
        );
    });

    $('.item', lightbox).on('swiperight', function (e) {
        var wd = $(this).width(),
        first = $(this).parent().find('.item').first(),
        last = $(this).parent().find('.item').last(),
        img = $(this).find('.img');
        itemWidth(img);

        $(last).insertBefore($(this));
        $(last).css({marginLeft: -wd, opacity: 0});
        $(this).parent().find('.item').eq(0).animate(
            {marginLeft: 0, opacity: 1},
            500,
            'swing'
        );
    });

    $('.arrows', lightbox).on('click', function (e) {
        e.stopPropagation();

        var wd = $(this).siblings('.items').find('.item').eq(0).width(),
            max = $(this).siblings('.items').find('.item').length,
            first = $(this).siblings('.items').find('.item').first(),
            last = $(this).siblings('.items').find('.item').last(),
            isRight = $(this).hasClass('right'),
            marginL = isRight ? -wd : 0,
            marginL0 = isRight ? 0: -wd,
            opacOn = isRight ? 1 : 0,
            opacOff = isRight ? 0 : 1;

        if (!isRight){
            $(last).insertBefore(first);
            $(last).css({marginLeft: -wd, opacity: 0});
            item++;
            if (item == max+1) {item = 1};
        } else {
            item--;
            if (item == 0) {item = max};
        };

        counter(item, max);

        var img = $(this).siblings('.items').find('.item').eq(0).find('.img');
        itemWidth(img);

        $(this).siblings('.items').find('.item').eq(1).animate(
            {opacity: opacOn}, 
            500,
            'swing');
        
        $(this).siblings('.items').find('.item').eq(0).animate(
            {marginLeft: marginL, opacity: 1},
            500,
            'swing',
            function(){
                if (isRight){
                    $(this).insertAfter(last);
                    $(this).css({ marginLeft: 0, opacity: 1});
                }
            }
        );
    });


    if (_ww > 768){
        $('.div-img-hover-2').hover(function (e) {
            
            $(this).find('.project-arrow-2').animate({opacity: 1}, 150);
            var htext = $(this).find('.project-content').height();
            if (htext == undefined){htext = 0};
            $(this).find('.div-hover-2').animate({height : htext+180}, 150);
            $(this).find('.project-content').css({'visibility': 'visible'});
            $(this).find('.project-content').animate({opacity: 1}, 150);
            //console.log('h : '+htext);
            
        }, function() {
            console.log('down');
            $(this).find('.div-hover-2').animate({height : 85}, 150);
            $(this).find('.project-arrow-2').animate({opacity: 0}, 150);

            $(this).find('.project-content').animate({opacity: 0}, 150, function(){
                $(this).find('.project-content').css({'visibility': 'hidden'});
            });
        });
    } else {
        $('.div-img-hover-2').on( "click", function( event ) {
            console.log('tap tap');
            var opened = $(this).find('.div-hover-2').hasClass('open'),
            ht = opened ? 85 : '100%',
            vis = opened ? 0 : 1;
            $(this).find('.div-hover-2').toggleClass('open');
            $(this).find('.div-hover-2').animate({height : ht}, 150);
            $(this).find('.project-arrow-2').animate({opacity: vis}, 150);
            $(this).find('.project-content').animate({opacity: 1}, 150);
            
        });
    }
    $('.div-hover').hover(function (e) {
        $(this).find('.project-arrow-2').animate({opacity: 1}, 150);
    }, function() {
        $(this).find('.project-arrow-2').animate({opacity: 0}, 50);
    });

    /* ------------------- open textbloc image --------------------- */

    $('.div-hover').hover(function (e) {
        $(this).find('.project-arrow-2').animate({opacity: 1}, 150);
        var htext = $(this).find('.project-content').height();
        if (htext == undefined){htext = 0};
        $(this).animate({height : htext+180}, 150);
        $(this).find('.project-content').css({'visibility': 'visible'});
        $(this).find('.project-content').animate({opacity: 1}, 150);
        console.log('h : '+htext);
    }, function() {
        $(this).find('.project-arrow-2').animate({opacity: 0}, 50);
        $(this).animate({height : 70}, 150);
        $(this).find('.project-content').animate({opacity: 0}, 150, function(){
            $(this).find('.project-content').css({'visibility': 'hidden'});
        });
    });

    /* ------------------- Popup video --------------------- */

    // if (_ww > 768){
    //     $('#popUpChristmas').modal('show');
    //     $('#closeModal').click( function (e) {
    //         e.preventDefault()
    //         $('#popUpChristmas').modal('hide');
    //     })
    //
    //
    //     $("#video1").click(function() {
    //         //console.log(this);
    //         if (this.paused) {
    //             this.play();
    //         } else {
    //             this.pause();
    //         }
    //     });
    //
    //
    //     $( document ).ready(function() {
    //         var ctrlVideo = document.getElementById("video1");
    //
    //         $('#buttonVideoFour').click(function(){
    //             console.log('teizt');
    //             if ($('#buttonVideoFour').hasClass("active")){
    //
    //                 ctrlVideo.play();
    //
    //                 $('#buttonVideoFour').removeClass("active");
    //                 $('#buttonVideoFour').addClass("video-active");
    //             }
    //         });
    //
    //         $('#video1').click(function() {
    //             ctrlVideo.pause();
    //             $('#buttonVideoFour').removeClass("video-active");
    //             $('#buttonVideoFour').addClass("active");
    //         });
    //     }); 
    // }
    //

})(jQuery);